var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from './index';
export var sizes = {
    xsmall: 374,
    small: 768,
    medium: 900,
    lardium: 1100,
    large: 1440,
    xlarge: 1920,
};
export var media = Object.keys(sizes).reduce(function (acc, label) {
    acc[label] = function (first) {
        var interpolations = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            interpolations[_i - 1] = arguments[_i];
        }
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      @media (min-width: ", "px) {\n        ", "\n      }\n    "], ["\n      @media (min-width: ", "px) {\n        ", "\n      }\n    "])), sizes[label] + 1, css.apply(void 0, [first].concat(interpolations)));
    };
    return acc;
}, {});
var templateObject_1;
