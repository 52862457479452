export var BLUE = '#00b1eb';
export var LIGHT_BLUE = '#225d92';
export var ALT_BLUE = '#002e5b';
export var HOVER_BLUE = '#3594c3';
export var ACTIVE_BLUE = '#00254d';
export var DARK_BLUE = '#003769';
export var DARKER_BLUE = '#00234b';
export var LIGHT_GRAY = '#e1e8ec';
export var GRAY = '#8994a0';
export var WHITE = '#ffffff';
export var BLACK = '#000000';
export var GREEN = '#0dc85c';
