var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import '@babel/polyfill';
import 'nodelist-foreach-polyfill';
import React from 'react';
import render from '@rmb185/react-renderhelper';
import CustomLazyLoad from '../renderComponents/CustomLazyLoad';
import componentMap from '../component_mapping';
var COMPONENT_TYPE_ATTRIBUTE = "data-component-type";
var ModuleLoader = function (props) {
    var _a = React.useState(null), module = _a[0], setModule = _a[1];
    React.useEffect(function () {
        import("../../../../gatsby/src/components/" + componentMap[props.moduleName]).then(function (Module) {
            if (Module && module === null) {
                setModule(React.createElement(Module.default, __assign({}, props.config)));
            }
        }).catch(function (err) { return console.log(err); });
    }, [module]);
    return (module ? module : (React.createElement("div", { style: { height: '100vh', color: 'white' } },
        "Error rendering ",
        props.moduleName)));
};
ModuleLoader.defaultProps = {
    moduleName: null,
    config: {},
};
var componentContainers = document.querySelectorAll("[" + COMPONENT_TYPE_ATTRIBUTE + "]");
if (componentContainers) {
    componentContainers.forEach(function (el, index) {
        var moduleName = el.dataset.componentType;
        var config = el.dataset.config || '{}';
        var configString = JSON.parse(config);
        var templateConfig = el.dataset.templateConfig || '{}';
        var templateConfigString = JSON.parse(templateConfig);
        var configItems = __assign({}, configString, templateConfigString);
        // TODO: Dynamically set height?
        if (index > 3) {
            render(React.createElement(CustomLazyLoad, { height: '100vh' },
                React.createElement(ModuleLoader, { moduleName: moduleName, config: configItems })), el);
        }
        else {
            render(React.createElement(ModuleLoader, { moduleName: moduleName, config: configItems }), el);
        }
    });
}
var pageContainers = document.querySelectorAll('[data-page-config]');
if (pageContainers) {
    pageContainers.forEach(function (el, index) {
        var conf = el.dataset.pageConfig || '{}';
        conf = JSON.parse(conf);
        var blocks = conf.blocks || [];
        var Content = (React.createElement(React.Fragment, null, blocks.map(function (block, i) {
            var type = block.type;
            return (React.createElement(ModuleLoader, { key: i, moduleName: type, config: block.config }));
        })));
        render(Content, el);
    });
}
// TODO: Fix this resolve in webpack instead
var GlobalStyles = require('../../../../gatsby/src/theme/index.ts').GlobalStyles;
render(React.createElement(GlobalStyles, null), document.querySelector('.global-style'));
/*
 * Quick fix to set all external links to open to a new tab
 * since Wagtail refuses to support this for editors >:[
 */
document.querySelectorAll('a').forEach(function (link) {
    if (link.href &&
        link.href.indexOf(window.location.hostname) < 0 &&
        !link.href.match(/^mailto:/) &&
        !link.href.match(/^tel:/) &&
        !link.href.match(/^#/)) {
        link.target = "_blank";
    }
});
export default null;
