export default {
    'LandingHero': 'Hero/LandingHero',
    'InteriorHero': 'Hero/InteriorHero',
    'Footer': 'Footer/FooterBasic',
    'MainNavigation': 'MainNavigation/MainNavigationBasic',
    'SafetyRecall': 'SafetyRecallBanner/SafetyRecallBanner',
    'FlexCTASet': 'Flex/FlexSetBasic',
    'FlexCardSet': 'Flex/FlexSetBasic',
    'FeaturedParts': 'FeaturedPart/FeaturedPartBox',
    'DetailContentSet': 'DetailContent/DetailContentSet',
    'DetailCTASet': 'DetailCTA/DetailCTASet',
    'LoginForm': 'LoginForm',
    'PDFListApp': 'PDF/PDFListApp',
    'PartListApp': 'Part/PartListApp',
    'UserProfileForm': 'UserProfileForm',
    'PasswordResetRequestForm': 'PasswordResetRequestForm',
    'PasswordResetChangeForm': 'PasswordResetChangeForm',
    "Newsletter": "Newsletter",
    'VideoListApp': 'Video/VideoListApp',
    'QualityTestApp': 'QualityTest/QualityTestApp',
    'QualityTestNav': 'QualityTest/QualityTestNav',
    "TopBanner": "TopBanner",
};
